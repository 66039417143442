import React from "react";
import { Helmet } from "react-helmet";
// TODO: remove once IE11 support deprecated
import "babel-polyfill"; // IE bug fix

import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import { useStoreState } from "easy-peasy";

import { FetchProfile } from "./profile/FetchProfile";
import {
  Applications,
  ForgottenPassword,
  Login,
  LoginWithBreathe,
  AssociateSoftwareToken,
  ChangePassword,
} from "./pages";
import { Layout } from "./components";

import useEnv from "./components/useEnv";

import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
config.autoAddCss = false

const App = () => {
  const { isProd } = useEnv();
  const isAuthenticated = useStoreState((state) => state.isAuthenticated);
  const profile = useStoreState((state) => state.profile);

  return (
    <>
      <Helmet>
        {!isProd && <meta name="robots" content="noindex" />}
      </Helmet>
      {isAuthenticated && <FetchProfile />}
      <Layout>
        <div
          className="w-full max-w-500 rounded-md bg-white relative mb-4 pt-10 box-shadow"
          role="main"
        >
          <img
            alt="companyLogo"
            src="/design/images/breathe_icon.svg"
            className="mx-auto h-16 w-auto"
          />
          <BrowserRouter>
            <Switch>
              <Route
                path="/login"
                render={() => {
                  return <Login />;
                }}
              />
              <Route
                path="/oauth/authorize"
                render={() => {
                  return <LoginWithBreathe />;
                }}
              />
              <Route
                path="/forgotten-password"
                render={() => {
                  return <ForgottenPassword />;
                }}
              />
              <Route
                path="/associate-software-token"
                render={() => {
                  return <AssociateSoftwareToken email={profile.email} />;
                }}
              />

              <Route
                path="/applications"
                render={() => {
                  return isAuthenticated ? (
                    <Applications />
                  ) : (
                    <Redirect to="/login" />
                  );
                }}
              />
              <Route
                path="/change-password"
                render={() => {
                  return isAuthenticated ? (
                    <ChangePassword />
                  ) : (
                    <Redirect to="/login" />
                  );
                }}
              />

              <Redirect to="/login" />
            </Switch>
          </BrowserRouter>
        </div>
      </Layout>
    </>
  );
};

export default App;
